import { assertFields } from '~/ts-utils';
import { getReviewById } from '@wix/ambassador-reviews-v1-enriched-live-site-review/http';
import {
  Review as ServerReview,
  ModerationStatus,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

type Request = IHttpClient['request'];

export const enrichWithModerationStatus = async (
  request: Request,
  review: ServerReview,
): Promise<ServerReview> => {
  try {
    assertFields(review, ['id'], 'review');
    let timedOut = false;
    let moderationStatus = ModerationStatus.IN_MODERATION;
    // we hope that 3s is enough time to apply moderation rules
    setTimeout(() => {
      timedOut = true;
    }, 3000);
    for (let i = 0; i < 3; i++) {
      const throttleTimeout = new Promise((res) => setTimeout(res, 1000));
      const { data } = await request(getReviewById({ request: { reviewId: review.id } }));
      const status = data?.review?.review?.moderation?.moderationStatus;
      if (status && status !== ModerationStatus.SUBMITTED) {
        moderationStatus = status as unknown as ModerationStatus;
        break;
      }
      if (timedOut) {
        break;
      }
      await throttleTimeout;
    }
    return {
      ...review,
      moderation: {
        moderationStatus,
      },
    };
  } catch (e) {
    return {
      ...review,
      moderation: { moderationStatus: ModerationStatus.IN_MODERATION },
    };
  }
};
