import { FetchInitialData } from './api';
import { buildProduct } from '~commons/testing/builders/product-builders';
import { buildConfiguration } from '~commons/testing/builders/configuration-builder';
import { ApplicationError } from '~/components/CollectionWidget/common/types';
import { Member } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';

export const fetchInitialDataEditorMock: FetchInitialData = async () => {
  const product = buildProduct();
  if (!product || !product.id || !product.url || !product.name) {
    return {
      type: 'error',
      error: new ApplicationError('Missing required product data!'),
    };
  }
  return {
    type: 'ok',
    value: {
      entity: {
        id: product.id,
        name: product.name,
        imageUrl: product.imageUrl ?? undefined,
        url: product.url,
      },
      configuration: buildConfiguration({
        title: {
          maxLength: 100,
        },
        body: {
          maxLength: 500,
        },
        media: {
          limit: 5,
        },
      }),
      currentMember: {
        id: '9d3f58ee-fb8a-458b-89fb-f59854d2933e',
        loginEmail: 'reviewsuser@test.com',
        status: 'APPROVED',
        contactId: '904a6d11-5471-4668-92a1-becf63d4f61c',
        contact: {
          contactId: '904a6d11-5471-4668-92a1-becf63d4f61c',
          phones: [],
          emails: [],
          addresses: [],
          customFields: {},
        },
        profile: { nickname: 'reviewsuser', slug: 'reviewsuser' },
        privacyStatus: 'PUBLIC',
        activityStatus: 'ACTIVE',
        createdDate: new Date('2022-03-25T13:25:04.000Z'),
        updatedDate: new Date('2022-03-29T11:35:36.005Z'),
      } as Member,
      existingUserReview: undefined,
    },
  };
};
