import { AppThunk } from '../types';
import { BaseParamsState } from './base-params-types';

export const SET_BASE_PARAMS = 'SET_BASE_PARAMS' as const;
export const SET_INSTANCE = 'SET_INSTANCE' as const;

export const setBaseParams = (payload: BaseParamsState) => ({
  type: SET_BASE_PARAMS,
  payload: { ...payload },
});

export const initializeBaseParams =
  (): AppThunk =>
  (dispatch, _getState, { wixCodeApi, instanceManager, flowApi }) => {
    const { namespace, entityId, requestLinkId, reviewRequestId, screenshotMode } =
      wixCodeApi.location.query;
    const { isEditor, isPreview } = flowApi.environment;
    dispatch(
      setBaseParams({
        namespace,
        entityId,
        origin: requestLinkId
          ? { type: 'REQUEST_LINK', requestLinkId }
          : reviewRequestId
          ? { type: 'REVIEW_REQUEST', reviewRequestId }
          : { type: 'UNKNOWN' },
        instance: instanceManager.getInstance(),
        isEditor,
        isPreview,
        isScreenshotMode: !!screenshotMode && screenshotMode !== 'false',
        baseUrl: wixCodeApi.location.baseUrl,
      }),
    );
  };

export const setInstance = (payload: string) => ({ type: SET_INSTANCE, payload });

type SetBaseParamsAction = ReturnType<typeof setBaseParams>;
type SetInstanceAction = ReturnType<typeof setInstance>;

export type BaseParamsActions = SetBaseParamsAction | SetInstanceAction;
