import {
  reviewSubmissionWarning,
  leaveAReviewButtonClicked,
  intentClickIntoReviewSection,
  publishReviewButtonClick,
  newReviewSubmittedForApproval,
  reviewPublishedSuccessfully,
} from '@wix/bi-logger-livesite-reviews/v2';
import { GUID } from '@wix/bi-logger-livesite-reviews/v2/types';
import { AllActions, State } from '../store/types';
import { BiEventsExecutor } from '~commons/bi/types';
import { createActionMatcher } from './create-action-matcher';
import {
  BI_CLICK_LEAVE_REVIEW_BTN,
  BI_CLICK_PUBLISH_REVIEW,
  BI_FOCUS_FORM_BODY,
  BI_FORM_VALIDATION_ERROR,
  BI_REVIEW_PUBLISH_SUCCESS,
  BI_REVIEW_SUBMITTED_FOR_APPROVAL,
} from '../store/actions/bi-actions';
import { AUTHOR_ROLE } from '~commons/bi/bi-constants';
import { getReviewImageCount, getReviewVideoCount } from '~commons/bi/bi-utils';
import { ReviewContent } from '~commons/types';

export const biEventsExecutor: BiEventsExecutor<AllActions, State> = ({
  action: _action,
  state,
  logger,
  flowAPI,
}) => {
  const getAuthorRole = () => {
    if (state.appState.type === 'READY' && state.appState.currentMember) {
      return state.appState.currentMember?.id === state.baseParams.instanceValues?.siteOwnerId
        ? AUTHOR_ROLE.OWNER
        : AUTHOR_ROLE.MEMBER;
    }
    return AUTHOR_ROLE.ANONYMOUS;
  };

  const parent_resource_id = state.baseParams.entityId ?? '';
  const author_role = getAuthorRole();
  const referral_info =
    state.baseParams.origin.type === 'REVIEW_REQUEST' ? 'email_notification' : '';
  const origin = 'collection_page';

  createActionMatcher(logger)
    .match([BI_FORM_VALIDATION_ERROR], reviewSubmissionWarning, (action) => {
      const { reviewId, missingRequiredFields } = action.payload;
      return {
        author_role,
        review_id: reviewId as GUID,
        warning_reason: missingRequiredFields.join(','),
        origin,
      };
    })
    .match([BI_CLICK_LEAVE_REVIEW_BTN], leaveAReviewButtonClicked, () => ({
      author_role,
      parent_resource_id,
      origin,
      referral_info,
    }))
    .match([BI_FOCUS_FORM_BODY], intentClickIntoReviewSection, () => ({
      author_role,
      origin,
      parent_resource_id,
    }))
    .match([BI_CLICK_PUBLISH_REVIEW], publishReviewButtonClick, (action) => {
      const content = action.payload;
      return {
        author_role,
        image_count: getReviewImageCount({ content }),
        video_count: getReviewVideoCount({ content }),
        is_edited: false,
        parent_resource_id,
        rating: content.rating,
        review_id: undefined as any,
        referral_info,
        origin,
      };
    })
    .match([BI_REVIEW_SUBMITTED_FOR_APPROVAL], newReviewSubmittedForApproval, (action) => {
      const review = action.payload;
      const { content = { media: [] } } = review;

      return {
        author_role,
        image_count: getReviewImageCount({ content: content as ReviewContent }),
        video_count: getReviewVideoCount({ content: content as ReviewContent }),
        parent_resource_id,
        rating: review.content?.rating ?? 0,
        review_id: review.id as GUID,
        review_text: review.content?.body ?? '',
        review_title: review.content?.title ?? '',
        referral_info,
        origin,
      };
    })
    .match([BI_REVIEW_PUBLISH_SUCCESS], reviewPublishedSuccessfully, (action) => {
      const review = action.payload;
      const { content = { media: [] } } = review;

      return {
        author_role,
        image_count: getReviewImageCount({ content: content as ReviewContent }),
        video_count: getReviewVideoCount({ content: content as ReviewContent }),
        is_edited: false,
        parent_resource_id,
        rating: review.content?.rating ?? 0,
        review_id: review.id as GUID,
        review_text: review.content?.body ?? '',
        review_title: review.content?.title ?? '',
        referral_info,
        approved_by: undefined as unknown as GUID,
        approver_role: '',
        is_verified: !!review.verified,
        reviews_count: 1,
        origin,
      };
    })
    .execute(_action);
};
