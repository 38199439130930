import thunk from '@wix/redux-thunk';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { identity } from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './reducer';
import { ExtraThunkArguments, State } from './types';
import { createAPI } from '../api/api';
import { InstanceManager } from '~commons/instance/get-instance';
import { createBiMiddleware } from './middleware/bi-middleware';

export const createReduxStore = ({
  listener,
  flowApi,
  instanceManager,
}: {
  listener: (state: State) => void;
  flowApi: ControllerParams['flowAPI'];
  instanceManager: InstanceManager;
}) => {
  const { httpClient } = flowApi;
  const composeEnhancers = identity;

  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        createBiMiddleware(instanceManager, flowApi),
        thunk.withExtraArgument<ExtraThunkArguments, State>({
          httpClient,
          errorMonitor: flowApi.errorMonitor,
          wixCodeApi: flowApi.controllerConfig.wixCodeApi,
          api: createAPI(flowApi),
          instanceManager,
          flowApi,
        }),
      ),
    ),
  );
  let oldState: State;
  store.subscribe(() => {
    const newState = store.getState();
    if (oldState === newState) {
      return;
    }
    oldState = newState;
    listener(newState);
  });
  return store;
};
