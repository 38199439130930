import { BaseParamsState } from './base-params-types';
import { AllActions } from '../types';
import { SET_BASE_PARAMS, SET_INSTANCE } from './base-params-actions';
import { parseInstance } from '~commons/instance/parse-instance';

export const baseParamsReducer = (
  state: BaseParamsState = { origin: { type: 'UNKNOWN' } },
  action: AllActions,
): BaseParamsState => {
  switch (action.type) {
    case SET_BASE_PARAMS:
      const { instance, instanceValues } = action.payload;
      return {
        ...action.payload,
        instanceValues: instanceValues
          ? instanceValues
          : instance
          ? parseInstance(instance)
          : undefined,
      };
    case SET_INSTANCE:
      return {
        ...state,
        instance: action.payload,
        instanceValues: parseInstance(action.payload),
      };
    default:
      return state;
  }
};
