import { ControllerParams } from '@wix/yoshi-flow-editor';
import { RequiredKeys, Result, unreachable } from '~/ts-utils';

import {
  getEntity,
  createReviewAndContact as apiCreateReview,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/http';
import {
  Configuration,
  EnrichedLiveSiteReview,
  Fieldset,
  Member,
  Source,
  SourceType,
  Review as ServerReview,
  ModerationStatus,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { Entity } from '../store/app-state/app-state-types';
import { ApplicationError } from '../types';
import { Contact, ReviewContent } from '~commons/types';
import { Origin } from '../store/base-params/base-params-types';
import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { fetchInitialDataEditorMock } from './mocks';
import { enrichWithModerationStatus } from '~commons/lib/api';

export type API = ReturnType<typeof createAPI>;

type Review = RequiredKeys<ServerReview, 'id'>;

export type FetchInitialData = ({
  namespace,
  entityId,
  reviewRequestId,
}: {
  namespace: string;
  entityId: string;
  reviewRequestId?: string;
}) => Promise<
  Result<
    {
      entity: Entity;
      configuration: Configuration;
      currentMember: Member | undefined;
      existingUserReview: EnrichedLiveSiteReview | undefined;
    },
    AmbassadorHTTPError | ApplicationError
  >
>;

const buildSource = (origin: Origin): Source | undefined => {
  switch (origin.type) {
    case 'REQUEST_LINK':
      return {
        type: SourceType.REQUEST_LINK,
        requestLinkOptions: {
          id: origin.requestLinkId,
        },
      };
    case 'REVIEW_REQUEST':
      return {
        type: SourceType.REVIEW_REQUEST,
        reviewRequestOptions: {
          id: origin.reviewRequestId,
        },
      };
    case 'UNKNOWN':
      return undefined;
    default:
      throw unreachable(origin);
  }
};

export const createAPI = (flowApi: ControllerParams['flowAPI']) => {
  const { httpClient } = flowApi;
  const { isEditor, isPreview } = flowApi.environment;
  const shouldShowMockData = isEditor || isPreview;

  const fetchInitialData: FetchInitialData = async ({ namespace, entityId, reviewRequestId }) => {
    try {
      const getEntityResponse = await httpClient.request(
        getEntity({
          entityId,
          namespace,
          reviewRequestId,
          fieldsets: [
            Fieldset.CONFIGURATION,
            Fieldset.CURRENT_MEMBER,
            reviewRequestId
              ? Fieldset.REVIEW_REQUEST_RECIPIENT_REVIEW
              : Fieldset.CURRENT_USER_REVIEW,
          ],
        }),
      );

      const {
        data: { entity: product, additionalData },
      } = getEntityResponse;
      const { config, currentMember, currentUserReview, reviewRequestRecipientReview } =
        additionalData ?? {};

      if (!product || !product.id || !product.url || !product.name || !config) {
        return {
          type: 'error',
          error: new ApplicationError('Missing required product data!', {
            entityId,
            product: JSON.stringify(product),
            config: JSON.stringify(config),
            requestId: getEntityResponse.requestId,
          }),
        };
      }

      return {
        type: 'ok',
        value: {
          entity: {
            id: product.id,
            name: product.name,
            imageUrl: product.imageUrl ?? undefined,
            url: product.url,
          },
          configuration: config,
          currentMember,
          existingUserReview: reviewRequestId ? reviewRequestRecipientReview : currentUserReview,
        },
      };
    } catch (e: any) {
      return {
        type: 'error',
        error: e as AmbassadorHTTPError,
      };
    }
  };

  return {
    fetchInitialData: shouldShowMockData ? fetchInitialDataEditorMock : fetchInitialData,
    createReview: async ({
      namespace,
      entityId,
      content,
      origin,
      contact: { name, email },
      isModerationEnabled,
    }: {
      namespace: string;
      entityId: string;
      content: ReviewContent;
      origin: Origin;
      contact: Partial<Contact>;
      isModerationEnabled: boolean;
    }): Promise<Result<Review, AmbassadorHTTPError | ApplicationError>> => {
      try {
        const {
          data: { response },
          requestId,
        } = await httpClient.request(
          apiCreateReview({
            namespace,
            entityId,
            authorName: name,
            ...(email ? { authorEmail: email } : {}),
            content,
            source: buildSource(origin),
          }),
        );

        if (response && response.review && response.review.id) {
          const enrichedReview = isModerationEnabled
            ? await enrichWithModerationStatus(httpClient.request.bind(httpClient), response.review)
            : {
                ...response.review,
                moderation: { moderationStatus: ModerationStatus.APPROVED },
              };
          return {
            type: 'ok',
            value: enrichedReview as Review,
          };
        } else {
          return {
            type: 'error',
            error: new ApplicationError('Missing required data!', {
              entityId,
              review: JSON.stringify(response?.review),
              requestId,
            }),
          };
        }
      } catch (e: any) {
        return {
          type: 'error',
          error: e as AmbassadorHTTPError,
        };
      }
    },
  };
};
