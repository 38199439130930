import { bindActionCreators } from 'redux';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createReduxStore } from './common/store/create-redux-store';
import { decorateActionsWithLogger } from '~commons/lib/monitoring';
import { controllerActions } from './controller/controller-actions';
import { getEnvParams } from '~commons/get-env-params';
import { createInstanceManager } from '~commons/instance/get-instance';
import { REVIEWS_APP_ID } from '~/app-ids';
import { setInstance } from './common/store/base-params/base-params-actions';
import { STORES_NAMESPACE } from '~/external-ids';

const createController: CreateControllerFn = async (controllerParams) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  return {
    async pageReady() {
      const flowAPI = controllerParams.flowAPI;
      const { setProps, wixCodeApi } = flowAPI.controllerConfig;
      const { isDebug, logNetwork } = getEnvParams(wixCodeApi);
      const instanceManager = createInstanceManager(wixCodeApi, REVIEWS_APP_ID);

      const store = createReduxStore({
        listener: (state) => setProps({ state, actions }),
        flowApi: flowAPI,
        instanceManager,
      });

      // handle app token refresh
      instanceManager.onChange(({ instance }) => {
        store?.dispatch(setInstance(instance));
      });

      const actions = decorateActionsWithLogger(
        bindActionCreators(controllerActions, store.dispatch),
        flowAPI.errorMonitor,
        isDebug || logNetwork === 'error',
      );

      setProps({
        state: store.getState(),
        actions,
        // https://wix.slack.com/archives/CAKBA7TDH/p1646144638497679?thread_ts=1643623637.998229&cid=CAKBA7TDH
        fitToContentHeight: true,
      });

      const namespace = wixCodeApi.location.query.namespace || STORES_NAMESPACE;
      const entityId = wixCodeApi.location.query.entityId;

      wixCodeApi.user.onLogin(() => {
        setTimeout(() => {
          actions.fetchInitialData({ namespace, entityId });
        });
      });

      actions.initializeBaseParams();
      actions.fetchInitialData({ namespace, entityId });
    },
  };
};

export default createController;
