import { InvalidFields } from '~commons/components/review-form/types';
import { ReviewContent } from '~commons/types';
import { Review } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';

export const BI_FORM_VALIDATION_ERROR = 'bi/FORM_VALIDATION_ERROR' as const;
export const biFormValidationError = (payload: {
  reviewId?: string;
  missingRequiredFields: InvalidFields;
}) => ({
  type: BI_FORM_VALIDATION_ERROR,
  payload,
});

export const BI_CLICK_LEAVE_REVIEW_BTN = 'bi/CLICK_LEAVE_REVIEW_BTN' as const;
export const biClickLeaveReviewBtn = () => ({
  type: BI_CLICK_LEAVE_REVIEW_BTN,
});

export const BI_FOCUS_FORM_BODY = 'bi/FOCUS_FORM_BODY' as const;
export const biFocusFormBody = () => ({
  type: BI_FOCUS_FORM_BODY,
});

export const BI_CLICK_PUBLISH_REVIEW = 'bi/CLICK_PUBLISH_REVIEW' as const;
export const biClickPublishReview = (payload: ReviewContent) => ({
  type: BI_CLICK_PUBLISH_REVIEW,
  payload,
});

export const BI_REVIEW_SUBMITTED_FOR_APPROVAL = 'bi/BI_REVIEW_SUBMITTED_FOR_APPROVAL' as const;
export const biReviewSubmittedForApproval = (payload: Review) => ({
  type: BI_REVIEW_SUBMITTED_FOR_APPROVAL,
  payload,
});

export const BI_REVIEW_PUBLISH_SUCCESS = 'bi/REVIEW_PUBLISH_SUCCESS' as const;
export const biReviewPublishSuccess = (payload: Review) => ({
  type: BI_REVIEW_PUBLISH_SUCCESS,
  payload,
});

export type BiActions =
  | ReturnType<typeof biFormValidationError>
  | ReturnType<typeof biClickLeaveReviewBtn>
  | ReturnType<typeof biFocusFormBody>
  | ReturnType<typeof biClickPublishReview>
  | ReturnType<typeof biReviewSubmittedForApproval>
  | ReturnType<typeof biReviewPublishSuccess>;
