import { AllActions } from '../types';
import { AppState } from './app-state-types';
import { FETCH_INITIAL_DATA_FAILURE, SET_INITIAL_DATA } from '../actions';

export const appStateReducer = (
  state: AppState = { type: 'LOADING' },
  action: AllActions,
): AppState => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      return {
        type: 'READY',
        entity: action.payload.entity,
        currentMember: action.payload.currentMember,
        configuration: action.payload.configuration,
      };
    case FETCH_INITIAL_DATA_FAILURE:
      return { type: 'ERROR', status: action.payload };
    default:
      return state;
  }
};
